import React, { useContext, useState,useRef } from 'react';
import { useEffect } from 'react';
//import { useOnLoadImages } from "../../../../utils/useOnLoadImages";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Hamburger from 'hamburger-react';
import Allpagemodal from '../allpagemodal'
import './style.css'
import API from '../../../../service/Api';
import { Link } from 'react-router-dom';
import { HomeContext } from '../../../../App';
import axios from 'axios';


const navbarlinkStyle = {
  // color: header[0].navlinkColor,
  // fontSize: headernav[0].navlinkFontsize
}

function CheckImage(url){

    var image = new Image();

    image.src = url;
    // console.log(image.src,image.complete);
    // if (!image.complete) {
    //     return false;
    // }
    // else if (image.height === 0) {
    //     return false;
    // }
    
    return true;
}


function Header(props) {

  const {eventTrack} =props
  const [ip,setIP] = useState('');
    
  const getData = async()=>{
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }

  useEffect(() => {
    getData()
    
  }, []);


  const data = useContext(HomeContext);
    
    const logo = data.logo_img
    const headerSectionContent = data.customization.headerSection.content;
    const headerSectionSty = data.customization.headerSection.style;

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
    };
  
    const handleMouseLeave = () => {
      setIsHover(false);
    };


    const headerBackground = {
      border: "1px solid",
      backgroundColor: headerSectionSty.backgroundColor,
      borderColor: headerSectionSty.borderBottom,
    };
    const navlinkBackground = {
      backgroundColor: headerSectionSty.backgroundColor,
    };
    const navlinkColor = {
      color: headerSectionSty.linkColor,
    };
    const ctaStyle = {
      backgroundColor: isHover
        ? headerSectionSty.ctaHoverBg
        : headerSectionSty.ctaBg,
      color: isHover
        ? headerSectionSty.ctaHoverTextColor
        : headerSectionSty.ctaTextColor,
      border: "1px solid",
      borderTopColor: isHover
        ? headerSectionSty.ctaHoverBg
        : headerSectionSty.ctaBg,
      borderLeftColor: isHover
        ? headerSectionSty.ctaHoverBg
        : headerSectionSty.ctaBg,
      borderRightColor: isHover
        ? headerSectionSty.ctaHoverBg
        : headerSectionSty.ctaBg,
      borderBottomColor: isHover
        ? headerSectionSty.ctaHoverBg
        : headerSectionSty.ctaBg,
    };


  const initModelData = { name: '', title: '', children: '' }

  const [flag, setFlag] = React.useState(false)
  const [model, setModel] = React.useState(initModelData)
  const [apiModel, setApiModel] = React.useState([])


  const [webname, setWebname] = React.useState([{}])

  React.useEffect(() => {
      fetch('data/website_config.json').then((res) => res.json()).then((data) => {
          setWebname(data);
          handleApiCall(data.WEBSITE_NAME,data.WEBSITE_ID)
      })

  }, [])
  const handleApiCall = async (web,websiteId) => {
      try {
      const article = { websiteName: web,webSiteId: websiteId};
      
        let res = await API.post("Content/getWebsiteContent", article);
        
        setApiModel(res.data[0])
  
      }
      catch (error) {
        console.error("whySectionerror", error.message);
      }
    };

  useEffect(() => {
    handleApiCall()
    // eventTrack("Rfc Index", "RFC Temp1")
  }, [])

  const setShow = (arg) => {
   
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {})
   
    setModel({ name: '', title: x.heading, children: x.content.text })
    setFlag(true)
  }

  const handleClick = () => {
      props.onButtonClick();
  };
  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand}
          // style={navbarColor}
          expand={expand} className='nav_bg' style={headerBackground}>

          <Container fluid>
            <div className='container'>
              <div className="nav_parent navbar-brand d-flex align-items-center">
               <Link to="/">
               <Navbar.Brand href="/" className="logoCenterText2" >
                  { CheckImage(`static/assets/images/${logo}`)? <img src={`static/assets/images/${logo}`} alt={webname.WEBSITE_NAME} className="px-2 logoimg" /> : webname.WEBSITE_NAME}
                </Navbar.Brand>
               </Link>

                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="Hamburger_boder">
                  <Hamburger direction="right" size={20}  className="hamburger"/>
                </Navbar.Toggle>

                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                  className="button button-effect">
                      
                  <Offcanvas.Body className='offcanvasbody' >
                    <Nav className="justify-content-end flex-grow-1 NavLinkStyle ulstylink"
                     style={{ headerBackground }}
                     >
                      <Nav.Link style={navlinkColor} href="#action1" className="navlinkstyle"   onClick={() => setShow('how_it_works')}>How it works</Nav.Link>
                      <Nav.Link style={navlinkColor}    href="#action2" className="navlinkstyle" onClick={() => setShow('rates')}> Rates and Fees</Nav.Link>
                      <Nav.Link style={navlinkColor}    href="#action3" className="navlinkstyle" onClick={() => setShow('contact')} > Contact Us</Nav.Link>
                    </Nav>
                    <div className="but_nav_start navBtn_mob">
                      <button className="get_start_btn" style={ctaStyle} onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave} onClick={handleClick} >
                        {headerSectionContent.ctaText}
                      </button>
                      
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </div>
            </div>
          </Container>
        </Navbar>
      ))}

      <Allpagemodal flag={flag} setFlagClose={() => setFlag(false)}
        name={model['name']} bodyTitle={model['title']}>
        <>{model['children']}</>
      </Allpagemodal>
    </>
  );

}


export default Header;