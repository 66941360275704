
import React from 'react'
import "./loader.css"

const WebsiteLoader = () => {
    return (
        <div>
            <div className="loader"></div>
        </div>
    )
}

export default WebsiteLoader