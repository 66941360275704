import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./style.css";
import "./floting.css";

import API from "../../../../../service/Api";
import Allpagemodal from "../../allpagemodal";
import { ErrorMessage } from "@hookform/error-message";
import useQuery from "../../../../../utils/useQuery";
import { createSearchParams, useNavigate } from "react-router-dom";
import { HomeContext } from "../../../../../App";

// import { data } from '../../data'

const Banner = React.forwardRef((props, ref) => {
  const data = useContext(HomeContext);
  const [focusedField, setFocusedField] = useState(null);
  const [fieldIsHover, setFieldIsHover] = useState(false);

  const bannerSectionContent = data.customization.bannerSection.content;
  const bannerSectionSty = data.customization.bannerSection.style;

  const banner_img = data.banner_img
  const maxAmpunt =data.max_amount

  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
 
  const [isHover, setIsHover] = useState(false);
  

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  
  
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleFieldMouseEnter = (field) => {
    setFieldIsHover(field);
  };
  const handleFieldMouseLeave = (field) => {
    setFieldIsHover(null);
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };
  const handleBlur = () => {
    setFocusedField(null);
  };
  const ctaStyle = {
    backgroundColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    color: isHover
      ? bannerSectionSty.ctaHoverTextColor
      : bannerSectionSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderLeftColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderRightColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderBottomColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
  };
  const themeColor = {
    color: bannerSectionSty.themeColor2,
  };
  const ctaButton = {
    color: bannerSectionSty.themeColor2,
  };
  const bannerBackground = {
    backgroundColor: bannerSectionSty.backgroundColor,
  };
  const headerText = {
    color: bannerSectionSty.headertextColor,
  };
  const paratext = {
    color: bannerSectionSty.paratextColor,
  };

  const termstext = {
    color: bannerSectionSty.termsTextColor,
  };
  const disclaimerLink = {
    color: bannerSectionSty.themeColor2,
    fontWeight: 700,
    cursor: "pointer",
  };
  const labelFocus = {
    color:bannerSectionSty.themeColor2,
  }
  const inputFocus = {
    borderColor:bannerSectionSty.themeColor2,
  }

  const initModelData = { name: "", title: "", children: "" };
  const [flag, setFlag] = React.useState(false);
  const [model, setModel] = React.useState(initModelData);
  const [apiModel, setApiModel] = React.useState([]);
  const [webname, setWebname] = React.useState([{}]);

  


  React.useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        setWebname(data);
      });
  }, []);

  const handleApiCall = async () => {
    try {
      if(webname.WEBSITE_NAME){
        const article = { websiteName: `${webname.WEBSITE_NAME}`,webSiteId:`${webname.WEBSITE_ID}`};
        
        let res = await API.post("Content/getWebsiteContent", article);
       
        setApiModel(res.data[0]);
      }
      
    } catch (error) {
      console.error("websiteContenterror", error.message);
    }
  };

  useEffect(() => {
    handleApiCall();
    
    // eventTrack("Rfc Index", "RFC Temp1")
  }, [webname]);

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});
    
    setModel({ name: "", title: x.heading, children: x.content.text });
    setFlag(true);
  };

// Form Sub


  const [formData, setformData] = useState({});
  const query = useQuery();
  const navigate = useNavigate();

  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
 
  // const queryName = query.getAll;
  

  const onSubmit = (data) => {
    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
      
    };
    setformData(myData);
   
    const params = { email: myData.email ,firstName:myData.firstName,lastName:myData.lastName, s1: myData.utm_campaign, s2: myData.s2,s3: myData.s3, uid: myData.uid, utm_medium: myData.utm_medium, amount: myData.amount};
    

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,

    });
  };

  return (
    <>
      {/* <!-- Frist Block --> */}
      <div
        className="container-fluid m-0 p-0 bolck_bg parent_container"
        style={bannerBackground}
      >
        <div className="addPaddingBlockFrist form_block">
          <div className="row">
            <div className="col-sm-12">
             
            </div>
          </div>
        </div>
        <div className="container ResponsivFristBlock addPaddingBlockFrist website_blocks">
          <div className="row AddTopPadding">
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6 p-0">
              <div className="">
                <div className="firstBlock_Text_parent">
                  <h1 className="firstBlock_Text" style={headerText}>
                    {bannerSectionContent.headingText
                      ? bannerSectionContent.headingText
                      : ""} {" "}

                    <span className="AmountColor" style={themeColor}>
                      {bannerSectionContent.headingThemeText
                        ? bannerSectionContent
                            .headingThemeText
                        : ""}
                    </span>
                    <br />
                  </h1>
                  <p className="firstBlock_para_text" style={paratext} ref={ref}>
                    {bannerSectionContent.subheadingText
                      ? bannerSectionContent.subheadingText
                      : ""}
                  </p>

                  <div className="PaddingAmountbtn">
                    <form id="myForm" onSubmit={handleSubmit(onSubmit)} >
                      <div className="d-flex presponsiveSty">
                        <div className="res_width">
                          <div className="row formalign_row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form_align">


                              <div className="field">
                                <div className="inputparent_marg">
                                  <input
                                  type="text"
                                  id="firstName"
                                    name="firstName"
                                    placeholder="Enter First Name"
                                    className="amount_but inline-edit"
                                    onFocus={() => handleFocus('firstName')}
                                    onBlur={handleBlur}
                                    style={focusedField === 'firstName'?inputFocus:fieldIsHover === 'firstName'? inputFocus:{}}
                                    onMouseEnter={() => handleFieldMouseEnter('firstName')}
                                    onMouseLeave={handleFieldMouseLeave}
                                    {...register("firstName", {
                                      required: "This input is required.",
                                      minLength:{
                                        value : 3,
                                        message:
                                          "Please enter at least 3 characters",
                                      }
                                    })}
                                  />
                                  <label
                                    htmlFor="firstName"
                                    style={focusedField === 'firstName' ? labelFocus : {}}
                                    // className={`${styles.lable_sty}`}
                                    // className="lableSty"
                                  >
                                    First Name
                                  </label>
                                </div>
                              </div>
                              <ErrorMessage
                                errors={errors}
                                name="firstName"
                                render={({ message }) => (
                                  <p className="error_color">{message}</p>
                                )}
                              />
                            </div>
                            
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form_align">

                              <div className="field">
                                <div className="inputparent_marg">
                                  <input
                                  type="text"
                                  id="lastName"
                                    name="lastName"
                                    placeholder="Enter Last Name"
                                    className="amount_but inline-edit"
                                    onFocus={() => handleFocus('lastName')}
                                    onBlur={handleBlur}
                                    style={focusedField === 'lastName'?inputFocus:fieldIsHover === 'lastName'? inputFocus:{}}
                                    onMouseEnter={() => handleFieldMouseEnter('lastName')}
                                    onMouseLeave={handleFieldMouseLeave}
                                    {...register("lastName", {
                                      required: "This input is required.",
                                      minLength:{
                                        value : 3,
                                        message:
                                          "Please enter at least 3 characters",
                                      }
                                    })}
                                  />
                                  <label
                                    htmlFor="lastName"
                                    style={focusedField === 'lastName'?labelFocus:{}}
                                    
                                    // className={`${styles.lable_sty}`}
                                    // className="lableSty"
                                  >
                                    Last Name
                                  </label>
                                </div>
                              </div>
                              <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={({ message }) => (
                                  <p className="error_color">{message}</p>
                                )}
                              />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form_align">
                            

                              <div className="field">
                                <div className="inputparent_marg">
                                  <input
                                    type="email"
                                    className="amount_but inline-edit"
                                    id="email"
                                    name="email"
                                    onFocus={() => handleFocus('email')}
                                    onBlur={handleBlur}
                                    placeholder="Email Address"
                                    
                                    style={focusedField === 'email'?inputFocus:fieldIsHover === 'email'? inputFocus:{}}
                                    onMouseEnter={() => handleFieldMouseEnter('email')}
                                    onMouseLeave={handleFieldMouseLeave}
                                    {...register("email", {
                                      required: "Email Address is required",

                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address",
                                      },
                                    })}
                                  />
                                  <label htmlFor="email" style={focusedField === 'email' ? labelFocus :{}}>Email Address</label>
                                </div>
                              </div>
                              <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => (
                                  <p className="error_color">{message}</p>
                                )}
                              />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form_align">
                              

                              <div className="field">
                                <div className="inputparent_marg">
                                  <input
                                    type="tel"
                                    className="amount_but inline-edit"
                                    placeholder="e.g. 800"
                                    name="amount"
                                    id="amount"
                                    onFocus={() => handleFocus('amount')}
                                    onBlur={handleBlur}
                                    style={focusedField === 'amount'?inputFocus:fieldIsHover === 'amount' ? inputFocus:{}}
                                    onMouseEnter={() => handleFieldMouseEnter('amount')}
                                    onMouseLeave={handleFieldMouseLeave}
                                    {...register("amount", {
                                      required: "This input is required.",
                                      pattern: {
                                        value: /^[1-9][0-9]*0$/,
                                        message:
                                          "This value should be a multiple of 50.",
                                      },
                                      min: {
                                        value: 100,
                                        message:
                                          `This value should be between 100 and ${maxAmpunt}.`,
                                      },
                                      max: {
                                        value: maxAmpunt,
                                        message:
                                          `This value should be between 100 and ${maxAmpunt}.`,
                                      },
                                    })}
                                  />
                                  <label
                                    htmlFor="amount"
                                    style={focusedField === 'amount' ? labelFocus : {}}
                                    // className={`${styles.lable_sty}`}
                                    // className="lableSty"
                                  >
                                    Loan Amount
                                  </label>
                                </div>
                              </div>
                              <ErrorMessage
                                errors={errors}
                                name="amount"
                                render={({ message }) => (
                                  <p className="error_color">{message}</p>
                                )}
                              />
                            </div>

                           
                         
                            <div>
                              <div className="firstBlock_subpara_parent AddPadingFirstBlockLink">
                                <p
                                  className="firstBlock_subpara_text"
                                  style={termstext}
                                >
                                  By clicking{" "}
                                  <span className="req_text" style={termstext}>
                                    '
                                    {
                                      bannerSectionContent
                                        .ctaText
                                    }
                                    '
                                  </span>{" "}
                                  ,you agree to our
                                  <span
                                    style={disclaimerLink}
                                    onClick={() => setShow("policy")}
                                  >
                                    {" "}
                                    Privacy Policy
                                  </span>{" "}
                                  ,
                                  <span
                                    style={disclaimerLink}
                                    onClick={() => setShow("terms")}
                                  >
                                    Terms
                                  </span>{" "}
                                  ,
                                  <span
                                    style={disclaimerLink}
                                    onClick={() => setShow("e_consent")}
                                  >
                                    E-Consent
                                  </span>
                                  ,
                                  <span
                                    style={disclaimerLink}
                                    onClick={() => setShow("rates")}
                                  >
                                    Rate &amp; Fees{" "}
                                  </span>
                                  and receive special offers from us and our
                                  marketing partners via email communication.
                                </p>
                              </div>

                              <div className="col-lg-12">
                                <div className="AddPadingCircleArrow">
                                  <button
                                    type="submit"
                                    id="firstPageSubmit"
                                    className="ArrowIconParentbanner"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={ctaStyle}
                                  >
                                    <div className="imgArrowDisplymob">
                                      {/* <img src="static/assets/images/Arrow.svg" alt="" className="img-fluid " /> */}
                                      <span>
                                        {
                                          data.customization.bannerSection
                                            .content.ctaText
                                        }
                                      </span>
                                      <img
                                        src="static/assets/images/Arrow.svg"
                                        alt=""
                                        className="img-fluid cus_sty_getstart"
                                      />
                                    </div>
                                    <div className="imgArrowDisplydesk" type="submit">
                                            <div className="buttonText">
                                                <div>
                                                    <a>{data.customization.bannerSection.content.ctaText? data.customization.bannerSection.content.ctaText: ""}
                                                  </a>
                                                </div>
                                                <div>
                                                    <img src="static/assets/images/Arrow.svg" alt="arrow_icon" className="img-fluid cus_sty_getstart" />
                                                </div>
                                            </div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                              <p className="millin_text" style={termstext}>
                                {bannerSectionContent
                                  .ctaBottomText1
                                  ? bannerSectionContent
                                      .ctaBottomText1
                                  : ""}

                                <span className="million" style={disclaimerLink}>
                                  &nbsp;
                                  {bannerSectionContent
                                    .ctaBottomText2
                                    ? bannerSectionContent
                                        .ctaBottomText2
                                    : ""}
                                  &nbsp;
                                </span>
                                {bannerSectionContent
                                  .ctaBottomText3
                                  ? bannerSectionContent
                                      .ctaBottomText3
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6 p-0">
              <div className="firstBlock_Text_parent ">
                <div className="BannerImgFirstBlock homeimg1">
                  <img
                    src={`static/assets/images/${banner_img}`}
                    alt=" "
                    className="img-fluid"
                  />
                </div>
                {/* <div className="BannerImgFirstBlock homeimg2">
                  <img src="static/assets/images/banner-img-mobile.svg" alt="" className="img-fluid" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </>
  );
});

export default Banner;

