import React, { useContext, useEffect } from "react";
import axios from "axios";
import "./style.css";
import Allpagemodal from "../allpagemodal";

import API from "../../../../service/Api";
import { HomeContext } from "../../../../App";

const Footer = () => {
  const data = useContext(HomeContext);
  
  let olaimg = '';
  const footerlogo = data.customization.footer.content.footerLogo;
  const footerBottomTextColor = {
    color: data.customization.footer.style.bottomParaText
  }
  
  const footerBackground = {
    backgroundColor:data.customization.footer.style.backgroundColor
  }
  
  const footerLogoTextColor = {
    color: data.customization.footer.style.logoParaTextColor,
  };
  const formUrl = data.form_url
  const olaToken =data.ola_script
    
  if(olaToken!=''){
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }
  
  
  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = React.useState(false);
  const [model, setModel] = React.useState(initModelData);
  const [apiModel, setApiModel] = React.useState([]);
  
 
  const [webname, setWebname] = React.useState([]);
  const [websiteId, setWebsiteId] = React.useState([]);

  React.useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        setWebname(data.WEBSITE_NAME);
        setWebsiteId(data.WEBSITE_ID);
        handleApiCall(data.WEBSITE_NAME,data.WEBSITE_ID);
      });
  }, []);


  // const websitename = `${webname.SERVER_URL}`

  const handleApiCall = async (web,websiteId) => {
    try {
      const article = { websiteName: web,webSiteId: websiteId};
      let res = await API.post("Content/getWebsiteContent", article);
      setApiModel(res.data[0]);
    } catch (error) {
      console.error("error", error.message);
    }
  };

  useEffect(() => {
    // handleApiCall();
    // handleApiCall1()
  }, []);

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});
    const x1 = x;
    const test = "faq";

    if (x.content.faq) {
      setModel({
        name: "",
        title: x.heading,
        children: JSON.stringify(x.content),
      });
    } else {
      setModel({ name: "", title: x.heading, children: x.content.text });
    }

    setFlag(true);
  };

  return (
    <div>
      {/* <!-- 9th block  Footer--> */}
      <div
        className="container-fluid m-0 p-0 bolck_bg_footer website_blocks"
        style={footerBackground}
      >
        <div className="container">
          <div className="FooterBlockContainer">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <div className="d-flex align-items-center">
                  <img
                    src={`static/assets/images/${footerlogo}`}
                    className="img-fluid imgStyFooterlogo2"
                    alt=""
                  />
                </div>
                <p
                  className="FooterLogoPara"
                  style={footerLogoTextColor}
                 
                >
                  {/* {data[0].footerpara} */}
                  {data.customization.footer.content.logoParaText}
                </p>

                <a
                  href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                  rel="noreferrer"
                  target="_blank"
                >
                  { olaimg != '' ? <img src={olaimg} alt="footer_logo" className="ola_logo"/> : ''}
                </a>
              </div>
              <div className="col-8 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div className="FooterLink">
                  
                  <p>
                    <a
                      // style={footerlink}
                      data-toggle="modal"
                      data-target="#HowItWorkModal"
                      onClick={() => setShow("how_it_works")}
                    >
                      How It Work
                    </a>
                  </p>
                  <p>
                    <a
                      // style={footerlink}
                      data-toggle="modal"
                      data-target="#serviceModal"
                      onClick={() => setShow("terms")}
                    >
                      Terms of Services
                    </a>
                  </p>
                  <p>
                    <a
                      // style={footerlink}
                      data-toggle="modal"
                      data-target="#privacyModal"
                      onClick={() => setShow("policy")}
                    >
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    <a
                      // style={footerlink}
                      data-toggle="modal"
                      data-target="#information"
                      onClick={() => setShow("ccpa")}
                    >
                      Do not sell my information
                    </a>
                  </p>
                  {/* {/* {footerlink.text}  {footerlink.text}   */}
                </div>
              </div>
              <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                <div className="FooterLink">
                  <p>
                    <a
                      //  style={footerlink}
                      data-toggle="modal"
                      data-target="#econsent"
                      onClick={() => setShow("e_consent")}
                    >
                      E-Consent
                    </a>
                  </p>
                  <p>
                    <a
                      //  style={footerlink}
                      data-toggle="modal"
                      data-target="#disclaimer"
                      onClick={() => setShow("disclaimer")}
                    >
                      Disclaimer
                    </a>
                  </p>
                  <p>
                    <a
                      //  style={footerlink}
                      data-toggle="modal"
                      data-target="#faq"
                      onClick={() => setShow("faq")}
                    >
                      FAQs
                    </a>
                  </p>
                  <p>
                    <a
                      //  style={footerlink}
                      data-toggle="modal"
                      data-target="#unsubscribe"
                      onClick={() => setShow("unsubscribe")}
                    >
                      Unsubscribe
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <p
              className="FooterPara" style={footerBottomTextColor}
              
            >
              {data.customization.footer.content.bottomParaText}
            </p>
          </div>
        </div>
      </div>

      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
        modelData={model}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </div>
  );
};

export default Footer;
