import React, { useState, useEffect, createContext, useRef } from "react";
// import API from "../../service/Api";

import {
  OffcanvasExample,
  Banner,
  Whyus,
  Howitworks,
  Faq,
  RequestMoney,
  Footer,
} from "./components";
import Header from "./components/header";
import axios from "axios";


const Home = (props) => {

  const bannerRef = useRef(null);
  const handleButtonClick = () => {
    bannerRef.current.scrollIntoView({ behavior: "smooth" });
  };


  return (

      <>
        
        <Header onButtonClick={handleButtonClick}/>
        <Banner ref={bannerRef}/>
        <Whyus />
        <Howitworks onButtonClick={handleButtonClick}/>
        <Faq />
        <RequestMoney onButtonClick={handleButtonClick}/>
        <Footer />
      </>
    
  );
};

export default Home;
