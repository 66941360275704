import React, { useContext } from "react";

import "./style.css";
import { HomeContext } from "../../../../../App";

const Whyus = () => {
  const data = useContext(HomeContext);

  const whyUsSection = data.section1_img

  let newArray = whyUsSection.split(',');



  const whyUsSectionContent = data.customization.whyUsSection.content;
  const whyUsSectionSty = data.customization.whyUsSection.style;

  const themeColor = {
    color: whyUsSectionSty.themeColor2,
  };

  const whyusBackground = {
    backgroundColor: whyUsSectionSty.backgroundColor,
  };

  const outerBackground = {
    backgroundColor: whyUsSectionSty.outerBgcolor,
  };
  const sectionTitle = {
    color: whyUsSectionSty.titleColor,
  };
  const iconHeading = {
    color: whyUsSectionSty.boldTextColor,
  };
  const iconParatext = {
    color: whyUsSectionSty.paraTextColor,
  };

  return (
    <div>
      <div
        className="second_block_parent_margin website_blocks"
        style={outerBackground}
      >
        <div className="container ResponsivFristBlock ">
          <div className="secondBlockSty" style={whyusBackground}>
            <div className="add_padding_top_sub_blc">
              <h1
                className="all_blcok_head all_block_headWhyus"
                style={sectionTitle}
              >
                {/* Why */}
                {whyUsSectionContent.titleText
                  ? whyUsSectionContent.titleText
                  : ""}{" "}
                <span className="AmountColor" style={themeColor}>
                  {whyUsSectionContent.titleTextTheme
                    ? whyUsSectionContent.titleTextTheme
                    : ""}
                  {/* Us? */}
                </span>
              </h1>
            </div>
            <div className="m-auto">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="AddMargin">
                    <img
                      src={`static/assets/images/${newArray[0]}`}
                      alt=""
                      className="whyus_icon img-fluid FooterThreeLogoWidth"
                    />
                    <p className="sequer_main" style={iconHeading}>
                    {whyUsSectionContent.icon1bold
                        ? whyUsSectionContent.icon1bold
                        : ""}

                      {/* 100% */}
                    </p>
                    <p className="sequer_sub" style={iconParatext}>
                      {whyUsSectionContent.icon1text
                        ? whyUsSectionContent.icon1text
                        : ""}
                      {/* Paperless Process */}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="AddMargin">
                    <img
                    src={`static/assets/images/${newArray[1]}`}
                      alt=""
                      className="whyus_icon img-fluid FooterThreeLogoWidth"
                    />
                    <p className="sequer_main" style={iconHeading}>
                      {/* Minimal */}
                      {whyUsSectionContent.icon2bold
                        ? whyUsSectionContent.icon2bold
                        : ""}
                    </p>
                    <p className="sequer_sub" style={iconParatext}>
                      {/* Documentation */}
                      {whyUsSectionContent.icon2text
                        ? whyUsSectionContent.icon2text
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="AddMargin">
                    <img
                    src={`static/assets/images/${newArray[2]}`}
                     
                      alt=""
                      className="whyus_icon img-fluid FooterThreeLogoWidth"
                    />
                    <p className="sequer_main " style={iconHeading}>
                      {/* Simple & Secure */}
                      {whyUsSectionContent.icon3bold
                        ? whyUsSectionContent.icon3bold
                        : ""}
                    </p>
                    <p className="sequer_sub" style={iconParatext}>
                      {/* Process */}
                      {whyUsSectionContent.icon3text
                        ? whyUsSectionContent.icon3text
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="AddMargin">
                    <img
                    src={`static/assets/images/${newArray[3]}`}
                      alt=""
                      className="whyus_icon img-fluid FooterThreeLogoWidth"
                    />
                    <p className="sequer_main" style={iconHeading}>
                      {/* 24/7 */}
                      {whyUsSectionContent.icon4bold
                        ? whyUsSectionContent.icon4bold
                        : ""}
                    </p>
                    <p className="sequer_sub" style={iconParatext}>
                      {/* Customer Support */}
                      {whyUsSectionContent.icon4text
                        ? whyUsSectionContent.icon4text
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
