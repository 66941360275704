import React, { useContext, useState } from 'react'

import { HomeContext } from "../../../../App";
const FooterFaq = (props) => {


  const FaqModalData =JSON.parse(props.modelData.children)
  const data = useContext(HomeContext);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);


  const faqSectionContent = data.customization.faq.content;
  const faqSectionSty = data.customization.faq.style;

  const activeQus = {
    color:faqSectionSty.themeColor2
  };
  const borderSty = {
    border: "1px solid",
    borderColor: isHover
      ? faqSectionSty.themeColor2
      : faqSectionSty.paraTextColor,
  };

  const handleMouseEnter = (index) => {
    setIsHover(index+1)
  };

  const handleMouseLeave = (index) => {
    if(index!='notActive'){
      setIsHover(0);
    }
    
  };

  const themeColor = {
    color: faqSectionSty.themeColor2 ? faqSectionSty.themeColor2 : "",
  };

  const faqBackground = {
    backgroundColor: faqSectionSty.backgroundColor
      ? faqSectionSty.backgroundColor
      : "",
  };

  const sectionTitle = {
    color: faqSectionSty.titleColor ? faqSectionSty.titleColor : "",
  };

  const quesText = {
    backgroundColor: faqSectionSty.quesbarbackground
      ? faqSectionSty.quesbarbackground
      : "",
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const activeTextQus = {
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const answerbarStyle = {
    backgroundColor: faqSectionSty.answerbarbackground
      ? faqSectionSty.answerbarbackground
      : "",
    color: faqSectionSty.paraTextColor ? faqSectionSty.paraTextColor : "",
  };
  
  const handelActive = (index) =>{
    setIsActive(index+1)
  }

  
  return (
    <div>    {/* FAQ  */}
    <div>
      {/* <!-- 7thBlock --> */}

      <div
        className="container-fluid m-0 p-0 bolck_bg website_blocks FooterFaqs_main"
        // style={faqBackground}
      >
        <div className="container ">
          <div className="">
            <div className="FaqBlockContainer  paddingfaqblock">
           
              <div className="">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample2"
                >
                  {FaqModalData.faq.map((item, index) => {
                    
                    return (
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {/* <p>{item.id}</p> */}
                        <div
                          className="accordion-item"
                          key={index}
                          style={(isHover === index+1) || (isActive === index+1)? borderSty:{}}
                          onMouseEnter={()=>handleMouseEnter(index)}
                          onMouseLeave={(isActive === index+1)?()=>handleMouseLeave('notActive'):()=>handleMouseLeave(index)}
                          onClick={()=>handelActive(index)}
                        >
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              
                              data-bs-target={`#${item.id}`}
                              aria-expanded="false"
                              aria-controls={item.id}
                              style={quesText}
                            >
                              <p
                                className="FaqBoxHeadding"
                              style={isActive === index+1? activeQus:{}}
                              >
                               
                                {item.qus}
                              </p>
                            </button>
                          </h2>
                          <div
                            id={item.id}
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample2"
                          >
                            <div
                              className="accordion-body"
                              // style={answerbarStyle}
                              dangerouslySetInnerHTML={{
                                __html: item.ans,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/*  */}</div>
  )
}

export default FooterFaq