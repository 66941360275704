import React, { useContext, useState } from 'react'
import './style.css'
import { HomeContext } from '../../../../../App';

// import { requestmoney } from '../../data'
const RequestMoney = ({ onButtonClick }) => {
    const data = useContext(HomeContext);
    
    const reqSectionContent = data.customization.requestmoney.content;
    const reqSectionSty = data.customization.requestmoney.style;

    const themeColor = {
        color: reqSectionSty.themeColor2
    }

    const requestmoneyBackground = {
        backgroundColor: reqSectionSty.backgroundColor 
    }

    const sectionTitle = {
        color: reqSectionSty.titleColor
     
    }

    const sectionParatext = {
        color:reqSectionSty.paraTextColor 
       
    }

    const buttonStyle = {
        backgroundColor: reqSectionSty.buttonBgcolor,
        borderColor: reqSectionSty.buttonBordercolor,
        color: reqSectionSty.buttonTextcolor,

    }
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
    };
  
    const handleMouseLeave = () => {
      setIsHover(false);
    };
    const ctaStyle = {
      backgroundColor: isHover
        ? data.customization.bannerSection.style.ctaHoverBg
        : data.customization.bannerSection.style.ctaBg,
      color: isHover
        ? data.customization.bannerSection.style.ctaHoverTextColor
        : data.customization.bannerSection.style.ctaTextColor,
      border: "1px solid",
      borderTopColor: isHover
        ? data.customization.bannerSection.style.ctaHoverBg
        : data.customization.bannerSection.style.ctaBg,
      borderLeftColor: isHover
        ? data.customization.bannerSection.style.ctaHoverBg
        : data.customization.bannerSection.style.ctaBg,
      borderRightColor: isHover
        ? data.customization.bannerSection.style.ctaHoverBg
        : data.customization.bannerSection.style.ctaBg,
      borderBottomColor: isHover
        ? data.customization.bannerSection.style.ctaHoverBg
        : data.customization.bannerSection.style.ctaBg,
    };
    const handleClick = () => {
        onButtonClick();
    };
    return (
        <div>
            {/* <!-- 8th --> */}
            <div className="container-fluid m-0 p-0 bolck_bg website_blocks">
                <div className="Bg8thblock" style={requestmoneyBackground}>
                  

                    <div className="container">
                        <div className="ready_margin">
                            <h1 className="all_blcok_head all_blcok_head_request top_ready" style={sectionTitle}>
                                {reqSectionContent.titleText}{" "}
                                {/* Ready to  */}
                                <span className="AmountColor" style={themeColor}>
                                    {reqSectionContent.titleTextTheme}

                                    {/* request? */}
                                </span></h1>
                            <p className="ParaGetStart" style={sectionParatext}>
                                {reqSectionContent.paraText}

                                {/* Submit your information from the comfort of your home and you may receive
                                funds as soon as the next business day, if approved by the lender. */}
                            </p>
                            <div className="GetstartButtonContainer GetstartButton_request">

                                <a >
                                    <button className="WhiteButtonGetStart"  onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave} onClick={handleClick}
                                    style={ctaStyle}>
                                        {/* Request Now  */}
                                        {reqSectionContent.CTAbuttonText}

                                        <img
                                            src="static/assets/images/Arrow.svg" alt=""
                                            className="img-fluid cus_sty_getstart" />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestMoney
