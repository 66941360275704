import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ContactForm from "../components/forms/contactform";
import UnsubscribeForm from "../components/forms/unsunscribeform";

import FooterFaq from "./Footer/FooterFaq";

function Allpagemodal({ flag, setFlagClose, name, bodyTitle, children, modelData }) {
  // const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  
  const faqData = children.props.children || {};

  // const doubled = JSON.parse(faqData).map((items) =>{
  //     return <h1>{items}</h1>
  // }

  //   )

  // const doubled = numbers.map((number) => number * 2);


  return (
    <>
      <a>{name}</a>

      <Modal show={flag} onHide={setFlagClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{bodyTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div>{children.props.children }</div> */}




          {
            bodyTitle != "Frequently Asked Questions" ?  (<div
              dangerouslySetInnerHTML={{ __html: children.props.children }}
            />) : ""
          }
          



          
          {bodyTitle == "Contact us" ? (
            <ContactForm /> 
          ) : bodyTitle == "Unsubscribe" ? (
            <UnsubscribeForm />
          ) : bodyTitle == "Frequently Asked Questions" ? (<FooterFaq modelData={modelData}/>) : ""
          
          }

          <div style={{ paddingBottom: "120px" }}>
            <Button variant="secondary" onClick={setFlagClose}>
              Close
            </Button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer >
                    
                 
                </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Allpagemodal;
