// import React from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
//import ReactGA from "react-ga4";
import { HomePage } from "./pages";
import "./App.css";
import MainForm from "./pages/Home/components/forms/MainForm";
// import imgFav from "../public/static/assets/images/favicon.png"
import React, { useState, useEffect, createContext } from "react";

import API from "./service/Api";
import useScript from "./utils/ScriptLoder";
import WebsiteLoader from "./utils/WebsiteLoader";
import TagManager from 'react-gtm-module';



const defaultData = {
  website_id: "",
  sub:"",
  domain_id: "",
  domain_name: "",
  server_id: "",
  logo_img: "",
  favicon_img: "",
  min_amount: "",
  max_amount: "",
  banner_img: "",
  section1_img: "",
  section2_img: "",
  section3_img: "",
  section4_img: "",
  primary_color: "",
  secondary_color: "",
  form_id: "",
  form_url: "",
  ga_script: "",
  push_script: "",
  ola_script: "",
  customization: {

    headerSection: {
      section: "",
      content: {
        ctaText: ""
      },
      style: {
        backgroundColor: "",
        mobileNavBg: "",
        linkColor: "",
        linkHoverColor: "",
        ctaBg: "",
        ctaTextColor: "",
        ctaBgBorder: "",
        ctaHoverBg: "",
        ctaHoverTextColor: ""
      },
    },

    bannerSection: {
      section: "",
      content: {
        headingText: "",
        subheadingText:
          "",
        headingThemeText: "",
        ctaText: "",
        ctaBottomText1: "",
        ctaBottomText2: "",
        ctaBottomText3: "",
      },
      style: {
        themeColor2: "",
        backgroundColor: "",
        headertextColor: "",
        paratextColor: "",
        termsTextColor: "",
        fieldHoverBorder: "",
        fieldErrorBorder: "",
        discLinkColor: "",
        ctaBg: "",
        ctaTextColor: "",
        ctaHoverBg: "",
        ctaHoverTextColor: "",
        fontFamily: "",
      },
    },
    
    whyUsSection: {
      section: "",
      content: {
        titleText: "",
        titleTextTheme: "",
        icon1boldText: "",
        icon1text: "",
        icon2bold: "",
        icon2text: "",
        icon3bold: "",
        icon3text: "",
        icon4bold: "",
        icon4text: "",
      },
      style: {
        themeColor2: "",
        outerBgcolor: "",
        backgroundColor: "",
        titleColor: "",
        boldTextColor: "",
        paraTextColor: "",
      },
    },
    howitworks: {
      section: "",
      content: {
        titleText: "",
        titleTextTheme: "",
        step1: "",
        step1headtext: "",
        step1paratext:
          "",
        icon1: "",
        step2: "",
        step2headtext: "",
        step2paratext:
          "",
        icon2: "",
        step3: "",
        step3headtext: "",
        step3paratext:
          "",
        icon3: "",
        CTAbuttonText: "",
      },
      style: {
        themeColor2: "",
        backgroundColor: "",
        titleColor: "",
        stepTextColor: "",
        boldTextColor: "",
        paraTextColor: "",
        //
        outerBgcolor: "",
        ctaBg: "",
        ctaTextColor: "",
        ctaHoverBg: "",
        ctaHoverTextColor: "",
      },
    },
    requestmoney: {
      section: "",
      content: {
        titleText: "",
        titleTextTheme: "",
        paraText:
          "",
        CTAbuttonText: "",
      },
      style: {
        themeColor2: "",
        backgroundColor: "",
        titleColor: "",
        paraTextColor: "",
        buttonBordercolor: "",
        buttonTextcolor: "",
        buttonBgcolor: "",
        outerBgcolor: "",
        ctaBg: "",
        ctaTextColor: "",
        ctaHoverBg: "",
        ctaHoverTextColor: "",
      },
    },
    faq: {
      section: "",
      content: {
        titleText: "",
        titleTextTheme: "",
        faq: [
          {
            qus: "",
            ans:
              "",
          },
          {
            qus: "",
            ans:
              "",
          },
          {
            qus: "",
            ans:
              "",
          },
          {
            qus: "",
            ans:
              "",
          },
        ],
      },
      style: {
        themeColor2: "",
        backgroundColor: "",
        quesbarbackground: "",
        answerbarbackground: "",
        titleColor: "",
        quesTextColor: "",
        paraTextColor: "",
        activeTextQus: "",
      },
    },

    footer: {
      section: "",
      content: {
        footerLogo: "",
        logoParaText: "",

        bottomParaText:
          "",
      },
      style: {
        logoParaTextColor: "",
        bottomParaTextColor: "",
        backgroundColor: "",
      },
    },
  },
};


export const HomeContext = createContext();

function getFaviconEl() {
  return document.getElementById("favicon");
}

const App = () => {

  // =================================
  const [loading, setLoading] = useState(true);
  const [toastMsg, setToastMsg] = useState(true);
  const [homeData, setHomeData] = useState(defaultData);

  const formUrl = homeData.form_url
  const Favicon =homeData.favicon_img
  const pushScriptUrl =homeData.push_script
  


  const [webname, setWebname] = React.useState([]);
  
  
  React.useEffect(() => {
    fetch("data/default_data.json")
      .then((res) => res.json())
      .then((data) => {
        setHomeData(data);
        
      });
    
    
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        setWebname(data);
        const myData = {
          websiteName: data.WEBSITE_NAME,
          webSiteId:data.WEBSITE_ID
        };
    
        dataFetchHandler(myData);
      });
  }, []);
  

  const dataFetchHandler = async (myData) => {
    const res = await API.post(
      "API/getLandingPageContent",myData
    );

    if (res.status === 200 && res.data.status === "success") {
      setHomeData(res.data.data);
      setLoading(false);
      testGA(res.data.data.ga_script)
      handleAppTitle(res.data.data.customization.title)
      
    } else {
      setToastMsg("error");
    }
    
  };
  

  useEffect(() => {
    dataFetchHandler();
    handleGoogle()
    
    
  }, []);

  const handleGoogle = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = `static/assets/images/${Favicon}`
  };

  const handleAppTitle = (codeTitlr) => {
    document.title = `${codeTitlr}`
  };

  

 // ReactGA.initialize("G-NCHYT7XKDK");

 // Ga Tracker===

 const location = useLocation(); // ReactGA.initialize("G-F0KGZ5PG0B");



const testGA = (codeGa) =>{
//    ReactGA.initialize(`${codeGa}`);

// //  ReactGA.send({ hitType: "pageview", page: "/home", title: "Custom Title" });
// //  ReactGA.send({hitType: "pageview", page: location.pathname});

//  //===== Ga Event======
//  const eventTrack = (category, action, label) => {
//   ReactGA.event({
//       category: category,
//       action: action,
//       label: label,
//   })
//  }
TagManager.initialize({ gtmId: `${codeGa}` });
}


// Pushnami ADD

useScript(pushScriptUrl)




  return (
    <>
      <HomeContext.Provider value={homeData}>
        
          {loading ? (<WebsiteLoader />):(
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/form" element={<MainForm formUrl={formUrl} />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        )}
      </HomeContext.Provider>
    </>
  );
};

export default App;
